import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6e0b1096 = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _1d32e432 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _39a1820c = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _b72dddf2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c254358 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _4be60048 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _d7102d7a = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _84d109b4 = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _c8e7a176 = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _7f905bb2 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _2fea187b = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _87977ba0 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _06df3bd1 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _7fe97ae0 = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _112118f6 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _01cb35f3 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _4816b325 = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _3e60a0be = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _7a296e3e = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _41abefda = () => interopDefault(import('../pages/top-locations/liget-budapest-visitor-center.vue' /* webpackChunkName: "pages/top-locations/liget-budapest-visitor-center" */))
const _e2f872ee = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _d9cbeedc = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _3ad6a4d2 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _3cfd6176 = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _f45cf794 = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _89d1d0d8 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _ce8356d4 = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _d6986b44 = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _33e65218 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _2c99ce86 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _9c0b6782 = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _3c27e809 = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _f03464dc = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _5dc1475d = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _89f156f2 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _9c5c3ca2 = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _0a440ce0 = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _75a337cc = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _b5626b72 = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _b12847a0 = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _ba9b2082 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _6e0b1096,
    name: "the-project___hu"
  }, {
    path: "/adatvedelem",
    component: _1d32e432,
    name: "privacy-policy___hu"
  }, {
    path: "/archivum",
    component: _39a1820c,
    name: "archive___hu"
  }, {
    path: "/en",
    component: _b72dddf2,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _2c254358,
    name: "newsletter___hu"
  }, {
    path: "/karrier",
    component: _4be60048,
    name: "career___hu"
  }, {
    path: "/kereses",
    component: _d7102d7a,
    name: "search___hu"
  }, {
    path: "/kozerdeku-adatok",
    component: _84d109b4,
    name: "public-information___hu"
  }, {
    path: "/latogatas",
    component: _c8e7a176,
    name: "visitor-information___hu"
  }, {
    path: "/magazin",
    component: _7f905bb2,
    name: "magazine___hu"
  }, {
    path: "/sajto",
    component: _2fea187b,
    name: "press___hu"
  }, {
    path: "/szakmai-anyagok",
    component: _87977ba0,
    name: "documents___hu"
  }, {
    path: "/terkep",
    component: _06df3bd1,
    name: "map___hu"
  }, {
    path: "/uzleti-partnereinknek",
    component: _7fe97ae0,
    name: "business-partners___hu"
  }, {
    path: "/varosligeti-programok",
    component: _112118f6,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/en/archive",
    component: _39a1820c,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _7fe97ae0,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _4be60048,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _2c254358,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _84d109b4,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _7f905bb2,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _06df3bd1,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _2fea187b,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _1d32e432,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _112118f6,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _d7102d7a,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _87977ba0,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _6e0b1096,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _c8e7a176,
    name: "visitor-information___en"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _01cb35f3,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/projekt/swiper",
    component: _4816b325,
    name: "the-project-swiper___hu"
  }, {
    path: "/sajto/mediatar",
    component: _3e60a0be,
    name: "press-media-library___hu"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _7a296e3e,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/top-helyek/liget-budapest-latogatokozpont",
    component: _41abefda,
    name: "top-locations-liget-budapest-visitor-center___hu"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _e2f872ee,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _d9cbeedc,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _3ad6a4d2,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _3cfd6176,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _f45cf794,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _89d1d0d8,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _ce8356d4,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _d6986b44,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media",
    component: _3e60a0be,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _01cb35f3,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _4816b325,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _7a296e3e,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _e2f872ee,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _d9cbeedc,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _3ad6a4d2,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/liget-budapest-visitor-center",
    component: _41abefda,
    name: "top-locations-liget-budapest-visitor-center___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _3cfd6176,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _f45cf794,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _89d1d0d8,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _ce8356d4,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _d6986b44,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _33e65218,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _2c99ce86,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/en/archive/:article",
    component: _9c0b6782,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _3c27e809,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _f03464dc,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _5dc1475d,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _89f156f2,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _9c5c3ca2,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _0a440ce0,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _75a337cc,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _b5626b72,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _b12847a0,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _33e65218,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _2c99ce86,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/archivum/:article",
    component: _9c0b6782,
    name: "archive-article___hu"
  }, {
    path: "/en/:slug",
    component: _ba9b2082,
    name: "slug___en"
  }, {
    path: "/karrier/:position",
    component: _f03464dc,
    name: "career-position___hu"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _5dc1475d,
    name: "public-information-data___hu"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _b12847a0,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/magazin/:article",
    component: _89f156f2,
    name: "magazine-article___hu"
  }, {
    path: "/programok/:program",
    component: _75a337cc,
    name: "programs-program___hu"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _0a440ce0,
    name: "program-series-programSeries___hu"
  }, {
    path: "/projekt/:project",
    component: _b5626b72,
    name: "the-project-project___hu"
  }, {
    path: "/sajto/:release",
    component: _9c5c3ca2,
    name: "press-release___hu"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _3c27e809,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/",
    component: _b72dddf2,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _ba9b2082,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
